import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { useAuth } from '../../services/auth/AuthService';
import { updateCampaign } from '../../services/api/ApiService';
import IncentiveMentionedPopup from './IncentiveMentionedPopup';
import { updateOnboardingStatus } from '../../utils';


const CampaignHeader = ({ campaign, setCampaign, setCampaignStatus }) => {
  const [data, setData] = useState([]);
  const [activeButtonDisabled, setActiveButtonDisabled] = useState(true);
  const [error, setError] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const { isOnboarded, userDetails } = useAuth();

  useEffect(() => {
    const stats = [
      { title: 'Created', value: new Date(campaign.created).toLocaleString() },
      { title: 'Status', value: campaign.status },
      { title: 'Payment method', value: campaign.payment_method || 'Not set' },
      { title: 'Incentive deadline', value: campaign.incentive_deadline },
      {
        title: 'Incentives',
        value: campaign.incentives.map((incentive) => incentive.name + ' ' + incentive.value).join(', '),
      },
    ];
    setData(stats);
    setPaymentMethod(campaign.payment_method);
  }, [campaign]);


  useEffect(() => {
    if (campaign && campaign.status === 'live') {
      setActiveButtonDisabled(false);
      setError('');
      if (!isOnboarded) {
        setCampaignStatus('live');
      }
    } else if (campaign && campaign.incentive_mentioned && paymentMethod) {
      setActiveButtonDisabled(false);
      setError('');
    } else if (paymentMethod) {
      setError('Please mention the incentive in your email.');
      setActiveButtonDisabled(true);
    } else if (!campaign.incentive_mentioned) {
      setError('Please add a payment method.');
      setActiveButtonDisabled(true);
    }
  }, [campaign.incentive_mentioned, paymentMethod]);

  const toggleCampaignStatus = async (status) => {
    setCampaign({ ...campaign, status });
    updateCampaign(campaign.id, { status }).then(null);
    if (isOnboarded) {
      return;
    }
    if (status === 'live') {
      setCampaignStatus('live');

      if (!isOnboarded) {
        const campaignId = userDetails?.onboarding_progress?.campaign_id ?? null;
        updateOnboardingStatus('campaign_activated', 6, userDetails.id, campaignId).then(null);
      }
    }
  };
  return (
    <>
      <Box p={4}>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
              {campaign.name}
            </Typography>
            {campaign.status === 'live' ? (
              <CheckCircleIcon color="success" fontSize="large" />
            ) : (
              <ErrorIcon color="error" fontSize="large" />
            )
            }
          </Box>
          <Box flexDirection="column" display="flex">
            <Button variant="contained" color="secondary"
              disabled={activeButtonDisabled}
              onClick={() => toggleCampaignStatus(campaign.status === 'live' ? 'inactive' : 'live')}>
              {campaign.status === 'live' ? 'Pause' : 'Activate'}
            </Button>
            {error && (
              <Typography variant="body2" sx={{ color: 'red' }}>
                {error}
              </Typography>
            )}
          </Box>
        </Box>
        <Grid container spacing={2} >
          {data.map((item, index) =>
            <Grid item xs={6} key={index}>
              <Box textAlign="left">
                <Typography
                  variant="body2"
                  color="textSecondary"
                  gutterBottom
                  sx={{ fontSize: '0.875rem' }}
                >
                  {item.title}
                </Typography>
                <Typography variant="body1" sx={{}}>
                  {item.value}
                </Typography>
              </Box>
            </Grid>,
          )}
        </Grid>

      </Box>
    </>
  );
};

export default CampaignHeader;