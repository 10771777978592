import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import WelcomeDialog from '../components/Onboarding/WelcomeDialog';
import OnboardingSteps from '../components/Onboarding/OnboardingSteps';
import { useAuth } from '../services/auth/AuthService';

function Dashboard() {
  const [showWelcome, setShowWelcome] = useState(false);
  const { userDetails, isOnboarded } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isOnboarded) {
      const onboardingProgress = userDetails?.onboarding_progress?.steps ?? [];
      const campaignId = userDetails?.onboarding_progress?.campaign_id ?? null;
      const maxIndex = onboardingProgress.reduce((acc, step) => {
        return step.completed === true ? Math.max(acc, step.index) : acc;
      }, 0);
      if (maxIndex < 1) {
        const hasSeenWelcome = JSON.parse(localStorage.getItem('hasSeenWelcome') || 'false');
        setShowWelcome(!hasSeenWelcome);
      }
      if (!campaignId) {
        navigate('/campaigns/create', {
          replace: true,
        });
        return
      }
      if (maxIndex === 1) {
        navigate(`/campaigns/${campaignId}/incentive`, {
          replace: true,
        });
      } else if (maxIndex === 2) {
        navigate('/integrations/gmail', {
          replace: true,
        });
      } else if (maxIndex === 3) {
        navigate('/billing', {
          replace: true,
        });
      } else if ([4, 5, 6].includes(maxIndex)) {
        navigate(`/campaigns/${campaignId}`, {
          replace: true,
        });
      } else {
        navigate('/campaigns', {
          replace: true,
        });
      }
    } else {
      navigate('/campaigns', {
        replace: true,
      });
    }
  }, []);

  const handleWelcomeClose = () => {
    localStorage.setItem('hasSeenWelcome', 'true');
    setShowWelcome(false);
    navigate('/campaigns/create');
  };

  return (<Box sx={{ p: 4 }}>
    <Typography variant="h4" gutterBottom>
      Welcome, {userDetails?.email}!
    </Typography>

    <Box sx={{ my: 4 }}>
      <OnboardingSteps activeStep={0} />
    </Box>

    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={() => navigate('/campaigns/create')}
      >
        Create Your First Campaign
      </Button>
    </Box>
    <WelcomeDialog
      open={showWelcome}
      onClose={handleWelcomeClose}
    />
  </Box>);
}

export default Dashboard;