import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import OnboardingSteps from './OnboardingSteps';

const WelcomeDialog = ({ open, onClose }) => {

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        Welcome to Speedable!
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" gutterBottom>
          Let's get you started with email incentives
        </Typography>
        <Typography variant="body1" paragraph>
          Speedable helps you get faster email responses by automatically rewarding recipients who reply within your
          specified timeframe.
        </Typography>

        <Box sx={{ my: 4 }}>
          <OnboardingSteps activeStep={-1} />
        </Box>

        <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 2 }}>
          Here's how it works:
        </Typography>
        <Typography variant="body1" paragraph>
          1. Create a campaign and set your response time limit
        </Typography>
        <Typography variant="body1" paragraph>
          2. Select a incentive product from our catalogue
        </Typography>
        <Typography variant="body1" paragraph>
          3. Connect your Gmail account to track responses
        </Typography>
        <Typography variant="body1" paragraph>
          4. Add payments using your voucher code
        </Typography>
        <Typography variant="body1" paragraph>
          5. Select email drafts to add incentives
        </Typography>
        <Typography variant="body1" paragraph>
          6. Recipients who reply in time automatically receive their reward!
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary" size="large">
          Let's Get Started
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WelcomeDialog;