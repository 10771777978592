import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  Stack,
  Switch,
  Paper,
  Grid,
  Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { useAuth } from '../../services/auth/AuthService';
import BackLink from '../../components/BackLink';
import CampaignHeader from './CampaignHeader';
import { getCampaignV2, updateUser } from '../../services/api/ApiService';
import CampaignStatistics from './CampaignStatistics';
import CampaignEmailTracking from './CampaignEmailTracking';
import OnboardingSteps from '../../components/Onboarding/OnboardingSteps';
import { updateCampaign } from '../../services/api/ApiService';

const emptyCampaign = {
  id: '',
  name: '',
  status: '',
  created: '',
  metrics: {
    drafted: 0,
    sent: 0,
    replied: 0,
    rewards_earned: 0,
    rewards_redeemed: 0,
    rewards_missed: 0,
  },
  emails: [],
  incentives: [],
  incentive_mentioned: false,
  incentive_deadline: '',
  charge_on: '',
  payment_method: '',
};

const CampaignDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [campaignStatus, setCampaignStatus] = useState('');
  const [onboardingStep, setOnboardingStep] = useState(0);
  const [isDraftsOpen, setIsDraftsOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [campaign, setCampaign] = useState(emptyCampaign);
  const { userDetails, isOnboarded, syncIsOnboarded, syncUserCredits } = useAuth();

  const [openCelebration, setOpenCelebration] = useState(false);

  const [isOnboardingCampaign, setIsOnboardingCampaign] = useState(false);

  const loadData = async () => {
    setIsLoading(true);
    try {
      const campaignData = await getCampaignV2(id);
      setCampaign(campaignData);
      syncUserCredits();
    } catch (error) {
      console.error('Failed to fetch drafts:', error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    if (isOnboarded) {
      setIsLoading(false);
      return;
    }
    if (id !== userDetails?.onboarding_progress?.campaign_id) {
      console.log('ignore as user is not managing onboarding campaign');
      setIsLoading(false);
      setIsOnboardingCampaign(false)
      return;
    }
    setIsOnboardingCampaign(true);
    if (campaignStatus === 'live') {
      setOnboardingStep(6);
      setOpenCelebration(true);
    } else if (campaignStatus === 'tracked') {
      setOnboardingStep(5);
      setIsDraftsOpen(false);
    } else if (campaignStatus === 'untracked') {
      setOnboardingStep(4);
      setIsDraftsOpen(true);
    } else {
      setOnboardingStep(1);
    }
    setIsLoading(false);
  }, [campaignStatus]);

  useEffect(() => {
    loadData().then(null);
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleCloseCelebration = async () => {
    await setOpenCelebration(false);
    await updateUser(userDetails.id, {
      is_onboarded: true,
      onboarding_progress: {
        campaign_id: campaign.id,
        steps: []
      },
    }).then(null);
    await syncIsOnboarded();
    window.location.reload();
  };

  const handleIncentiveToggle = async (incentive_mentioned) => {
    if (campaign.status === 'live' && !incentive_mentioned) {
      return;
    }
    setCampaign({ ...campaign, incentive_mentioned: incentive_mentioned });
    updateCampaign(campaign.id, { incentive_mentioned: incentive_mentioned }).then(null);
  };

  const exampleIncentiveText = useMemo(() => {
    return [
      'We use Speedable to speed up email conversations. Simply reply withing ', `${campaign.incentive_deadline}`, ' to get a coffee on me or donate the value to charity.'
    ];
  }, [campaign])

  const handleCopyText = useCallback(() => {
    navigator.clipboard.writeText(exampleIncentiveText.join(''));
  }, [campaign]);

  return (
    <>
      {isOnboardingCampaign && <OnboardingSteps activeStep={onboardingStep} displayText={false} />}

      {isLoading && <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>}
      {isOnboardingCampaign ? <BackLink path="/billing" /> : <BackLink path="/campaigns" />}
      <CampaignHeader campaign={campaign} setCampaign={setCampaign} setCampaignStatus={setCampaignStatus} />
      <CampaignStatistics campaign={campaign} />

      {/* Incentive Mentioned STARTS */}
      <Box p={2} mb={2} bgcolor="background.paper" borderRadius={1} boxShadow={1}>
        <Stack spacing={2} direction='row' justifyContent={{ xs: "space-between", sm: "flex-start" }} alignItems="center">
          <Typography variant="body1" color="textPrimary">
            Incentive mentioned
          </Typography>
          <Switch
            checked={campaign.incentive_mentioned || false}
            onChange={() => handleIncentiveToggle(!campaign.incentive_mentioned)}
            color="primary"
          />
        </Stack>
        <Typography variant="body1" color="textSecondary">
          Make sure to mention the incentive in your email before you send it - copy and paste example text shared below or write your own
        </Typography>
        <Typography mt={2} variant="body1" color="textPrimary" fontWeight="bold">
          Example Text
        </Typography>
        <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }} justifyContent="flex-start" alignItems={{ xs: "flex-end", sm: "center" }}>
          <Typography variant="body1" color="textPrimary" mr={2} align="left">
            {exampleIncentiveText[0]}<strong>{exampleIncentiveText[1]}</strong>{exampleIncentiveText[2]}
          </Typography>
          <Button onClick={handleCopyText} color="primary">
            Copy to Clipboard
          </Button>
        </Stack>
      </Box>
      {/* Incentive Mentioned ENDS */}

      {/* Tabs Section */}
      <AppBar position="static" color="default" elevation={0}>
        <Box flexDirection="row" display="flex" justifyContent="space-between" alignItems="center">
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Email Tracking" />
            {/*<Tab label="Settings" />*/}
          </Tabs>
          <Box flexDirection="row" display="flex" justifyContent="space-between" alignItems="center">
            <Button
              onClick={() => setIsDraftsOpen(!isDraftsOpen)}
              variant="contained"
              color="primary"
              startIcon={<FontAwesomeIcon icon={faGoogle} />}
              sx={{ '&:hover': { backgroundColor: '#357ae8' }, mx: 2 }}
            >
              Add Draft
            </Button>
            <Button
              onClick={() => loadData()}
              variant="contained"
              color="secondary"
              sx={{ '&:hover': { backgroundColor: '#357ae8' }, mx: 2 }}
            >
              Refresh
            </Button>
          </Box>
        </Box>
      </AppBar>

      {tabIndex === 0 && (
        <Box mb={2}>
          <CampaignEmailTracking campaign={campaign} isDraftsOpen={isDraftsOpen} setIsDraftsOpen={setIsDraftsOpen}
            setCampaign={setCampaign} campaignStatus={campaignStatus}
            setCampaignStatus={setCampaignStatus} />
        </Box>
      )}

      {/* Celebration Modal */}
      <Dialog open={openCelebration} onClose={() => handleCloseCelebration()}>
        <DialogTitle>Congratulations!</DialogTitle>
        <DialogContent>
          <p>You have successfully completed the onboarding process. 🎉</p>
          <p>Your campaign is now live and ready to reward recipients for replying to your emails.</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseCelebration()} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CampaignDetail;