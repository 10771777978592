import React from 'react';
import { Card, CardContent, CardMedia, Radio, Typography } from '@mui/material';

const ProductCard = ({ image, title, description, price, currency, selected, onChange }) => {
  return (
    <Card
      sx={{
        maxWidth: 345,
        position: 'relative',
        cursor: 'pointer',
        border: selected ? '2px solid #1976d2' : '2px solid transparent',
        transition: 'all 0.3s ease',
      }}
      onClick={onChange}
    >
      <CardMedia
        component="img"
        height="300"
        image={image}
        alt={title}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2" gutterBottom color="text.secondary">
          {description}
        </Typography>
        <Typography variant="h6" component="div">
          {currency}&nbsp;{price}
        </Typography>
      </CardContent>
      <Radio
        checked={selected}
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          backgroundColor: 'white',
          '&:hover': { backgroundColor: 'white' },
        }}
      />
    </Card>
  );
};

export default ProductCard;