import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Container, Link, Stack, Typography } from '@mui/material';
import ProductSelector from './../../components/ProductSelector';

import { useAuth } from './../../services/auth/AuthService';
import { useNavigate, useParams } from 'react-router-dom';
import OnboardingSteps from './../../components/Onboarding/OnboardingSteps';
import { createCampaignIncentiveV2, getCampaignV2, updateCampaignIncentiveV2 } from './../../services/api/ApiService';

export const CampaignIncentive = () => {
    const [campaign, setCampaign] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { isOnboarded } = useAuth();
    const navigate = useNavigate();
    const params = useParams()
    const [formData, setFormData] = React.useState({
        runa_product_code: '',
    });
    const [priceInfo, setPriceInfo] = useState({
        price: 5.00,  // default price
        currency: '£', // default currency
    });

    const handleProductSelect = (productId, price, currency) => {
        // Ensure price is a valid number
        const validPrice = !isNaN(price) ? parseFloat(price) : 0;  // Default to 0 if invalid price

        setFormData({
            runa_product_code: productId,
        });

        setPriceInfo({
            price: validPrice,
            currency,
        });
    };

    const handleSubmit = useCallback(async (event) => {
        try {
            console.log('formData', formData, params.id)
            event.preventDefault();
            if (!formData.runa_product_code.trim()) {
                alert('Please select a product.');
                return;
            }

            setIsSubmitting(true);
            const incentiveData = {
                campaign_id: params.id,
                runa_product_code: formData.runa_product_code,
            };
            if (campaign && campaign.incentives.length > 0) {
                await updateCampaignIncentiveV2(campaign.id, incentiveData);
            } else {
                await createCampaignIncentiveV2(incentiveData);
            }
            navigate('/integrations/gmail')
        } catch (error) {
            setIsSubmitting(false);
        }
    }, [formData, params]);

    const fetchCampaignData = async (campaignId) => {
        try {
            const campaignData = await getCampaignV2(campaignId);
            if (!campaignData) {
                navigate('/campaigns/create', {
                    replace: true,
                });
                return;
            }
            setCampaign(campaignData);
        } catch (error) {
            console.error('Failed to fetch campaign data:', error);
            navigate('/campaigns/create', {
                replace: true,
            });
        }
    }

    useEffect(() => {
        if (isOnboarded) {
            navigate('/campaigns', {
                replace: true,
            });
            return;
        }
        if (!params.id) {
            navigate('/campaigns/create', {
                replace: true,
            });
            return;
        }
        fetchCampaignData(params.id);
    }, [])

    return (
        <Container maxWidth="md">
            {!isOnboarded &&
                <OnboardingSteps activeStep={1} displayText={false} />
            }
            <form onSubmit={handleSubmit}>
                <Stack spacing={4}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        Incentive value: {priceInfo.price ? `${priceInfo.currency}${priceInfo.price.toFixed(2)}` : '£0.00'}
                    </Typography>
                    <ProductSelector
                        selectedProduct={formData.runa_product_code}
                        onProductSelect={handleProductSelect}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                        <Button
                            type="button"
                            variant="outlined"
                            size="large"
                            component={Link}
                            onClick={() => navigate('/dashboard')}
                            sx={{ flexGrow: 1, mr: 2 }}
                        >
                            Back to Dashboard
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            disabled={isSubmitting}
                            sx={{ flexGrow: 1 }}
                        >
                            {isSubmitting ? 'Please wait...' : 'Next: Add Integration'}
                        </Button>
                    </Box>
                </Stack>
            </form>
        </Container>
    );
};