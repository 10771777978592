import coffeeCup from '../assets/coffee-cup.jpeg';
import tree from '../assets/tree.jpeg';
import firstAidKit from '../assets/first-aid-kit.jpeg';

export const products = [
  {
    id: 'TRPL10',
    title: 'Plant 10 Trees',
    description: 'Help the environment by planting a tree in your name',
    image: tree,
    price: '5.00',
    country: 'GB',
    currency: '£',
  },
  {
    id: 'NERO',
    title: 'Nero Coffee',
    description: 'Treat yourself or someone else to a delicious coffee',
    image: coffeeCup,
    price: '5.00',
    country: 'GB',
    currency: '£',
  },
  {
    id: 'DUNKIN',
    title: 'Dunkin\' Coffee',
    description: 'Treat yourself or someone else to a delicious coffee',
    image: coffeeCup,
    price: '5.00',
    country: 'US',
    currency: '$',
  },
  {
    id: 'AMRCS',
    title: 'American Cancer Society',
    description: 'Donate to the American Cancer Society',
    image: firstAidKit,
    price: '5.00',
    country: 'US',
    currency: '$',
  },
];