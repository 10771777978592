import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Link, Stack, Typography } from '@mui/material';
import DeadlineInput from './DeadlineInput';
import CampaignNameInput from './CampaignNameInput';
import ProductSelector from './ProductSelector';
import {
  createCampaign,
  createCampaignIncentiveV2,
  updateCampaign,
  updateCampaignIncentiveV2,
} from '../services/api/ApiService';
import { useAuth } from '../services/auth/AuthService';
import { useNavigate } from 'react-router-dom';
import OnboardingSteps from './Onboarding/OnboardingSteps';
import { updateOnboardingStatus } from '../utils';

const CampaignForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { hasCredits, isOnboarded, userDetails } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = React.useState({
    name: '',
    hours: '',
    runa_product_code: '',
  });
  const [priceInfo, setPriceInfo] = useState({
    price: 5.00,  // default price
    currency: '£', // default currency
  });

  const handleChange = (field) => (event) => {
    setFormData({
      ...formData,
      [field]: event.target.value,
    });
  };

  const handleProductSelect = (productId, price, currency) => {
    // Ensure price is a valid number
    const validPrice = !isNaN(price) ? parseFloat(price) : 0;  // Default to 0 if invalid price

    setFormData({
      ...formData,
      runa_product_code: productId,
    });

    setPriceInfo({
      price: validPrice,
      currency,
    });
  };

  // No need to load campaign data if user is onboarded
  // useEffect(() => {
  //   if (!isOnboarded) {
  //     const campaignData = JSON.parse(localStorage.getItem('campaignData'));
  //     if (campaignData) {
  //       setFormData({
  //         name: campaignData.campaignName,
  //         hours: campaignData.incentiveDeadline,
  //         runa_product_code: campaignData.runaProductCode,
  //       });
  //     }
  //   }
  // }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formData.name.trim()) {
      alert('Please enter a campaign name.');
      return;
    }

    if (!formData.hours.trim()) {
      alert('Please enter a deadline.');
      return;
    }

    // if (!formData.runa_product_code.trim()) {
    //   alert('Please select a product.');
    //   return;
    // }

    setIsSubmitting(true);
    const campaignId = JSON.parse(localStorage.getItem('campaignData'))?.campaignId;
    if (campaignId) {
      await updateOnboardingCampaign(campaignId);
    } else {
      await createCampaignFunc();
    }
    setIsSubmitting(false);
  };

  const createCampaignFunc = async () => {
    try {
      const chargeOn = hasCredits ? 'credits' : 'card';
      const campaignResponse = await createCampaign({
        name: formData.name, incentive_deadline: formData.hours, charge_on: chargeOn,
      });
      const campaignId = campaignResponse.id;
      if (isOnboarded) {
        const incentiveData = {
          campaign_id: campaignId,
          runa_product_code: formData.runa_product_code,
        };
        await createCampaignIncentiveV2(incentiveData);
      }
      if (!isOnboarded) {
        // const campaignData = {
        //   campaignId,
        //   campaignName: formData.name,
        //   incentiveDeadline: formData.hours,
        //   chargeOn,
        //   runaProductCode: formData.runa_product_code,
        // };
        // localStorage.setItem('campaignData', JSON.stringify(campaignData));
        await updateOnboardingStatus('campaign_created', 1, userDetails.id, campaignId);
        // navigate('/integrations/gmail');
        navigate(`/campaigns/${campaignId}/incentive`, {
          replace: true,
        });
      } else {
        navigate(`/campaigns/${campaignId}`);
      }
    } catch (error) {
      console.error('Failed to create campaign or incentives:', error);
      alert('Failed to create campaign or incentives. Please try again.');
    }
  };

  const updateOnboardingCampaign = async (campaignId) => {
    try {
      const chargeOn = hasCredits ? 'credits' : 'card';
      await updateCampaign(campaignId, {
        name: formData.name, incentive_deadline: formData.hours, charge_on: chargeOn,
      });
      const incentiveData = {
        campaign_id: campaignId,
        runa_product_code: formData.runa_product_code,
      };
      await updateCampaignIncentiveV2(campaignId, incentiveData);
      // const campaignData = {
      //   campaignId,
      //   campaignName: formData.name,
      //   incentiveDeadline: formData.hours,
      //   chargeOn,
      //   runaProductCode: formData.runa_product_code,
      // };
      // localStorage.setItem('campaignData', JSON.stringify(campaignData));
      await updateOnboardingStatus('campaign_created', 1, userDetails.id, campaignId);
      navigate('/integrations/gmail');
    } catch (error) {
      console.error('Failed to create campaign or incentives:', error);
      alert('Failed to create campaign or incentives. Please try again.');
    }
  };

  return (
    <Container maxWidth="md">
      {!isOnboarded &&
        <OnboardingSteps activeStep={0} displayText={false} />
      }
      <form onSubmit={handleSubmit}>
        <Stack spacing={4}>
          <CampaignNameInput
            value={formData.name}
            onChange={handleChange('name')}
          />
          <DeadlineInput
            value={formData.hours}
            onChange={handleChange('hours')}
          />
          {isOnboarded && (
            <>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                Incentive value: {priceInfo.price ? `${priceInfo.currency}${priceInfo.price.toFixed(2)}` : '£0.00'}
              </Typography>
              <ProductSelector
                selectedProduct={formData.runa_product_code}
                onProductSelect={handleProductSelect}
              />
            </>
          )}
          {!isOnboarded ? (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
              <Button
                type="button"
                variant="outlined"
                size="large"
                component={Link}
                onClick={() => navigate('/dashboard')}
                sx={{ flexGrow: 1, mr: 2 }}
              >
                Back to Dashboard
              </Button>
              <Button
                type="submit"
                variant="contained"
                size="large"
                disabled={isSubmitting}
                sx={{ flexGrow: 1 }}
              >
                {isSubmitting ? 'Creating...' : 'Next: Select Incentive'}
              </Button>
            </Box>
          ) : (
            <Button
              type="submit"
              variant="contained"
              size="large"
              disabled={isSubmitting}
              sx={{ flexGrow: 1 }}
            >
              {isSubmitting ? 'Creating...' : 'Create Campaign'}
            </Button>
          )}
        </Stack>
      </form>
    </Container>
  );
};

export default CampaignForm;