import { updateUser } from './services/api/ApiService';

const updateOnboardingStatus = async (step_name, index, userId, campaignId) => {
  // const onboardingProgress = userDetails?.onboarding_progress?.lastStep;
  // const currentStepIndex = onboardingProgress.findIndex((step) => step.name === step_name);
  // if (currentStepIndex === -1) {
  //   onboardingProgress.push({ name: step_name, completed: true, index: index });
  // } else {
  //   onboardingProgress[currentStepIndex].completed = true;
  // }
  await updateUser(userId, {
    onboarding_progress: {
      steps: [{
        name: step_name,
        completed: true,
        index: index,
      }],
      campaign_id: campaignId,
    }
  });
};

const validatePasswordMeetsCriteria = (password) => {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!_%*?&])[A-Za-z\d@$!_%*?&]{8,}$/;
  return passwordRegex.test(password);
}

export { updateOnboardingStatus, validatePasswordMeetsCriteria };
