import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import DraftsList from './DraftList';
import { createThread, deleteThread, fetchGmailDraftsV2 } from '../../services/api/ApiService';
import { useAuth } from '../../services/auth/AuthService';
import { updateOnboardingStatus } from '../../utils';

const CampaignEmailTracking = ({
  campaign,
  setCampaign,
  isDraftsOpen,
  setIsDraftsOpen,
  setCampaignStatus,
  campaignStatus,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [trackedEmails, setTrackedEmails] = useState([]);
  const [draftEmails, setDraftEmails] = useState([]);
  const { userDetails, isOnboarded } = useAuth();

  const loadData = async () => {
    setIsLoading(true);
    try {
      const draftsData = await fetchGmailDraftsV2();
      setDraftEmails(draftsData);
    } catch (error) {
      console.error('Failed to fetch drafts:', error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isDraftsOpen) {
      loadData().then(null);
    }
  }, [isDraftsOpen]);

  useEffect(() => {
    setTrackedEmails(campaign.emails);
    // console.log(campaign.emails);
    if (campaign.emails.length > 0) {
      setCampaignStatus('tracked');
      if (!isOnboarded) {
        const campaignId = userDetails?.onboarding_progress?.campaign_id ?? null;
        if (campaignId === campaign.id) {
          updateOnboardingStatus('drafts_attached', 5, userDetails.id, campaignId).then(null);
        }
      }
    } else {
      setCampaignStatus('untracked');
    }
  }, [campaign.emails]);


  const getStatusColor = (status) => {
    const colors = {
      draft: 'info',
      sent: 'primary',
      replied: 'success',
      pending: 'warning',
    };
    return colors[status.toLowerCase()];
  };

  const getRewardStatusColor = (status) => {
    const colors = {
      sent: 'primary',
      claimed: 'success',
      not_claimed: 'warning',
      expired: 'error',
    };
    return colors[status.toLowerCase()];
  };
  const handleTrackEmail = async (email) => {
    const threadData = {
      summary: email.subject,
      type: 'email',
      campaign_id: campaign.id,
      meta: { gmail_message_id: email.id },
      from_email: email.from_,
    };
    const thread = await createThread(threadData);

    const newTrackedEmail = {
      from_email: email.from_,
      subject: email.subject,
      status: 'DRAFT',
      reward: 'NOT_CLAIMED',
      recipient: email.recipient,
      thread_id: thread.id,
      id: email.id,
    };
    setTrackedEmails([...trackedEmails, newTrackedEmail]);
    setDraftEmails(draftEmails.filter((draft) => draft.id !== email.id));
    setCampaign({
      ...campaign,
      emails: [...campaign.emails, newTrackedEmail],
      metrics: { ...campaign.metrics, tracked: campaign.metrics.tracked + 1 },
    });
  };

  const handleUntrackEmail = async (email) => {
    deleteThread(email.thread_id).then(null);
    setTrackedEmails(trackedEmails.filter((tracked) => tracked.thread_id !== email.thread_id));
    const newDraftEmail = {
      id: email.id,
      from_: email.from_email,
      subject: email.subject,
      recipient: email.recipient,
    };
    setDraftEmails([...draftEmails, newDraftEmail]);
    setCampaign({
      ...campaign,
      emails: campaign.emails.filter((tracked) => tracked.thread_id !== email.thread_id),
      metrics: { ...campaign.metrics, tracked: campaign.metrics.tracked - 1 },
    });
  };

  return (
    <Box mt={3}>
      <DraftsList
        open={isDraftsOpen}
        isLoading={isLoading}
        onClose={() => setIsDraftsOpen(false)}
        drafts={draftEmails}
        onTrackEmail={handleTrackEmail}
        refreshDrafts={() => loadData()}
      />
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        Attach unsent email drafts to incentivize recipients who meet your campaign conditions.
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>From</TableCell>
              <TableCell>Subject</TableCell>
              <TableCell>Recipient</TableCell>
              <TableCell>Sent</TableCell>
              <TableCell>Replied</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Reward Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trackedEmails.map((email, index) => (
              <TableRow key={index}>
                <TableCell>{email.from_email}</TableCell>
                <TableCell>{email.subject}</TableCell>
                <TableCell>
                  {email.recipient}
                </TableCell>
                {email.sent_at ?
                  <TableCell>{new Date(email.sent_at).toLocaleString()}</TableCell>
                  : <TableCell></TableCell>}
                {email.replied_at ?
                  <TableCell>{new Date(email.replied_at).toLocaleString()}</TableCell>
                  : <TableCell></TableCell>}
                <TableCell>
                  <Chip
                    label={email.status}
                    color={getStatusColor(email.status)}
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <Chip
                    label={email.reward.replace('-', ' ')}
                    color={getRewardStatusColor(email.reward)}
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handleUntrackEmail(email)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CampaignEmailTracking;